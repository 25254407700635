/* BaseProfile.css */
.profile-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.profile-header {
    text-align: center;
}

.profile-picture {
    text-align: center;
    margin-bottom: 20px;
}

.profile-picture img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.profile-details {
    text-align: center;
}

.profile-details h2 {
    margin: 10px 0;
}

.profile-details p {
    margin: 5px 0;
}

.profile-section {
    margin-top: 20px;
    text-align: left;
}

.profile-section h3 {
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 14px;
    color: #777;
}

.profile-section p {
    margin: 5px 0;
    line-height: 1.5;
}